import React from 'react'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Section, Heading2, TextDesc } from './styles'

interface Pagebody {
	pagebody: {
		raw: string
	}
}

const PageDescription = ({ pagebody }: Pagebody): JSX.Element => {
	const options = {
		renderNode: {
			[BLOCKS.HEADING_2]: function Head2(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <Heading2>{children}</Heading2>
			},
			[BLOCKS.PARAGRAPH]: function Paragraph(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <TextDesc>{children}</TextDesc>
			},
		},
	}

	return <Section>{documentToReactComponents(JSON.parse(pagebody.raw), options)}</Section>
}

export default PageDescription
