import React from 'react'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Wave from '../../../../sea-site-theme/src/svg/wave'
import { PowerHouseProp, WhichModelProp } from '../../pages/dolphin/commercial'
import {
	FullSection,
	TwoBannerContainer,
	PowerHouseContainer,
	WaveLogoContainer,
	TwoBannerHeading2,
	WhichModelContainer,
	WhichModelHead,
	WhichModelText,
	WhichModelLink,
} from './styles'

interface TwoBannerProp {
	powerhouse: PowerHouseProp
	whichmodel: WhichModelProp
}

const options = {
	renderNode: {
		[BLOCKS.HEADING_2]: function Head2(
			node: Block | Inline | Text,
			children: React.ReactChild | React.ReactFragment | null | undefined
		) {
			return (
				<TwoBannerHeading2>
					{children
						?.toString()
						.split('\n')
						.map((text, i: number) => {
							return <span key={i}>{text}</span>
						})}
				</TwoBannerHeading2>
			)
		},
	},
}

const whichOptions = {
	renderNode: {
		[BLOCKS.HEADING_2]: function Head2(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <WhichModelHead>{children}</WhichModelHead>
		},
		[BLOCKS.PARAGRAPH]: function Paragraph(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <WhichModelText>{children}</WhichModelText>
		},
	},
}

const TwoBanner = ({ powerhouse, whichmodel }: TwoBannerProp): JSX.Element => {
	const { mainActionText, mainActionUrl } = whichmodel
	return (
		<FullSection>
			<TwoBannerContainer>
				<PowerHouseContainer>
					<WaveLogoContainer>
						<Wave size="220" />
					</WaveLogoContainer>
					{documentToReactComponents(JSON.parse(powerhouse.description.raw), options)}
				</PowerHouseContainer>
				<WhichModelContainer>
					{documentToReactComponents(JSON.parse(whichmodel.description.raw), whichOptions)}
					<WhichModelLink to={'/' + mainActionUrl}>{mainActionText}</WhichModelLink>
				</WhichModelContainer>
			</TwoBannerContainer>
		</FullSection>
	)
}

export default TwoBanner
