import React, { LegacyRef, useRef, useState } from 'react'
import { BLOCKS, Block, Inline, Text, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { PlayCircle, PauseCircle } from 'react-feather'
import Wave from '../../../../sea-site-theme/src/svg/wave'
import {
	HeroSection,
	HeroItemsContainer,
	HeroButtons,
	HeroSeeAllLink,
	HeroSeeAll,
	HeroBookLink,
	HeroBook,
	Bold,
	Heading1,
	BannerText,
	DolphinLogo,
	WaveContainer,
	HeroLogos,
} from './styles'

interface HeroProp {
	brandUrl: {
		file?: {
			url?: string
		}
		description?: string
	}
	mainActionText: string
	mainActionUrl: string
	secondActionText: string
	secondActionUrl: string
	description: {
		raw: string
	}
	videourl: {
		file?: {
			url?: string
		}
	}
	bannerSources: {
		media: string
		src: string
		srcSet: string
		srcSetWebp: string
		srcWebp: string
	}[]
}

const heroBannerOptions = {
	renderMark: {
		[MARKS.BOLD]: function BoldText(text: React.ReactChild | React.ReactFragment | null | undefined) {
			return <Bold>{text}</Bold>
		},
	},
	renderNode: {
		[BLOCKS.HEADING_1]: function Head1(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <Heading1>{children}</Heading1>
		},
		[BLOCKS.PARAGRAPH]: function Paragprah(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <BannerText>{children}</BannerText>
		},
	},
}

const Hero = ({
	mainActionText,
	mainActionUrl,
	secondActionText,
	secondActionUrl,
	description,
	videourl,
	brandUrl,
	bannerSources,
}: HeroProp): JSX.Element => {
	const [control, setControl] = useState(false)
	const play = useRef<HTMLVideoElement | null>(null)
	const playVidHandler = () => {
		setControl(true)
		play != null || play != undefined ? play.current?.play() : null
	}
	const pauseVidHandler = () => {
		setControl(false)
		play != null || play != undefined ? play.current?.pause() : null
	}
	return (
		<HeroSection>
			<video ref={(play as unknown) as LegacyRef<HTMLVideoElement> | undefined} width="100%" height="100%" loop>
				<source src={`${videourl?.file?.url}`} type="video/mp4"></source>
				<track kind="captions"></track>
			</video>
			<HeroItemsContainer sources={control === false ? bannerSources : []}>
				{control === false ? (
					<>
						<HeroLogos>
							<DolphinLogo src={brandUrl?.file?.url} alt={brandUrl?.description}></DolphinLogo>
							<WaveContainer>
								<Wave size="170" />
							</WaveContainer>
						</HeroLogos>
						{documentToReactComponents(JSON.parse(description.raw), heroBannerOptions)}
						<HeroButtons>
							{mainActionUrl != undefined || mainActionUrl != null ? (
								<HeroSeeAllLink to={'/' + mainActionUrl}>
									{mainActionText != undefined || mainActionText != null ? (
										<HeroSeeAll>{mainActionText}</HeroSeeAll>
									) : (
										''
									)}
								</HeroSeeAllLink>
							) : (
								''
							)}
							{/* {secondActionUrl != undefined || secondActionUrl != null ? (
								<HeroBookLink to={'/' + secondActionUrl}>
									{secondActionText != undefined || secondActionText != null ? (
										<HeroBook>{secondActionText}</HeroBook>
									) : (
										''
									)}
								</HeroBookLink>
							) : (
								''
							)} */}
						</HeroButtons>
					</>
				) : (
					''
				)}
				{control === false ? (
					<PlayCircle onClick={playVidHandler} className="play" color="#fff" width="70" height="70" />
				) : (
					<PauseCircle onClick={pauseVidHandler} className="pause" color="#fff" width="70" height="70" />
				)}
			</HeroItemsContainer>
		</HeroSection>
	)
}

export default Hero
