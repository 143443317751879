import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import axios from 'axios'
import qs from 'qs'
import { StyledForm, HomeButtonRow, HomeButton } from '../../../../sea-site-theme/src/components/form/styles'
import { Formik } from 'formik'
// import { flat, validationSwitch, validationSchema } from '../../../../sea-site-theme/src/components/form/utils'
import { InputItems, initialValues, validationSchema } from '../../../../sea-site-theme/src/components/form/utils'
import FormFieldGroup from '../../../../sea-site-theme/src/components/form/FormFieldGroup'
import FormFieldSingle from '../../../../sea-site-theme/src/components/form/FormFieldSingle'
import { FullSection, RequestSection, Title, Reminder } from './styles'
import PopUp from '../../../../sea-site-theme/src/components/modal/forms'

interface QuoteProps {
	node: {
		title: string
		description: string | undefined
		endpoint: string
		items: InputItems
		node_locale: string
	}
}

const QuoteRequest = ({ locale }: { locale: string }): JSX.Element => {
	const [showDialog, setShowDialog] = React.useState(false)
	const open = () => setShowDialog(true)
	const close = () => setShowDialog(false)
	const { quoterequest } = useStaticQuery<{
		quoterequest: {
			edges: QuoteProps[]
		}
	}>(graphql`
		query quoteRequestQuery {
			quoterequest: allContentfulForm(filter: { title: { eq: "Request a quote" } }) {
				edges {
					node {
						title
						description
						endpoint
						node_locale
						items {
							... on ContentfulFormField {
								helpText
								inputAutocomplete
								inputId
								inputName
								inputPlaceholder
								inputRequired
								inputType
								textareaCols
								textareaRows
								icon
								validationText
								width
								options
								node_locale
								label
							}
							... on ContentfulFormGroup {
								title
								items {
									width
									validationText
									textareaRows
									textareaCols
									options
									node_locale
									label
									inputType
									inputRequired
									inputPlaceholder
									inputName
									inputId
									inputAutocomplete
									icon
									helpText
								}
							}
						}
					}
				}
			}
		}
	`)
	const [{ node: request }] = quoterequest.edges
	return (
		<FullSection>
			<RequestSection>
				<Title>{request.title}</Title>
				<Formik
					initialValues={initialValues(request.items)}
					validationSchema={validationSchema(request.items)}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						setSubmitting(true)
						const url = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
						const body = {
							origin: 'Contact Us WebSite',
							orgid: '00D58000000JkgU',
							retURL: 'https://maytronics.com.au/dolphin/commercial/?form=commercial',
							'00N4H00000E2wq9': 'https://maytronics.com.au/dolphin/commercial',
							subject: 'Commercial - Quote',
							name: `${values.firstName} ${values.lastName}`,
							phone: values.phone,
							'00N4H00000E2wqB': values.streetAddress,
							'00N4H00000E2wq6': values.city,
							'00N4H00000E2wqA': values.state,
							'00N4H00000E2wqC': values.postCode,
							'00N4H00000E2wq7': 'Australia',
							description: values.message,
							recordType: '0124H0000006NyI',
						}
						locale === 'en-AU' && (await axios.post(url, qs.stringify(body)))
						await axios.post('/.netlify/functions/graphql', {
							query: `
								mutation createForm(
									$formName: String!
									$subject: String
									$firstName: String
									$lastName: String
									$phone: String
									$address: AddressInput
									$message: String
									$locale: String
								) {
									createForm(
										formName: $formName
										subject: $subject
										firstName: $firstName
										lastName: $lastName
										phone: $phone
										address: $address
										message: $message
										locale: $locale
									) {
										_id
									}
								}
							`,
							variables: {
								locale,
								formName: 'commercial',
								subject: 'commercial-quote',
								firstName: values.firstName,
								lastName: values.lastName,
								phone: values.phone,
								address: {
									fullAddress: `${values.streetAddress}, ${values.city} ${values.state} ${values.postCode}`,
									line1: values.streetAddress,
									city: values.city,
									state: values.state,
									postalCode: values.postCode,
									country: locale === 'en-AU' ? 'Australia' : 'New Zealand',
								},
								message: values.message,
							},
						})
						resetForm()
						setSubmitting(false)
						return open()
					}}
				>
					{({ isSubmitting, errors, touched, values }) => (
						<StyledForm>
							{request.items.map((item, index: number) => {
								if (item.items) {
									const { title, items } = item
									return (
										<FormFieldGroup
											key={title !== undefined ? title : '' + index}
											color="#fff"
											{...{ items, errors, touched, values }}
										/>
									)
								} else {
									return (
										<FormFieldSingle
											key={item.inputName + index}
											overrideWidth="full"
											color="#fff"
											{...{
												item: { ...item, helpText: null },
												errors,
												touched,
												values,
											}}
										/>
									)
								}
							})}
							<Reminder>
								I wish to receive updates and promotions from Maytronics.<br></br>
								For more details, please see our{' '}
								<a href="https://maytronics.com.au/privacy" target="_blank" rel="noopener noreferrer">
									privacy policy.
								</a>{' '}
							</Reminder>
							<HomeButtonRow>
								<HomeButton type="submit" disabled={isSubmitting}>
									{isSubmitting ? 'Sending...' : 'Send Request'}
								</HomeButton>
							</HomeButtonRow>
						</StyledForm>
					)}
				</Formik>
				<PopUp showDialog={showDialog} close={close} />
			</RequestSection>
		</FullSection>
	)
}

QuoteRequest.propTypes = {
	locale: PropTypes.string,
}

export default QuoteRequest
